
import { defineComponent, ref, onMounted } from "vue";
// import ChannelDatatable from "@/components/kt-datatable/ChannelDataTable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { ElNotification } from "element-plus";
import { ElLoading } from "element-plus";
import { Field, Form } from "vee-validate";
// import Editor from '@tinymce/tinymce-vue'
import { TEMPLATES } from "@/helper/globalUrl"
import { TEMPLATEID } from "@/helper/globalConst"
import tinymce from 'tinymce';
import router from "@/router";
import store from "@/store";
import * as Yup from "yup";
import { hideModal } from "@/core/helpers/dom";
import Tab from "@/views/communicationService/Tab.vue";
import Globals from "@/views/common/Globals.vue";

export default defineComponent({
  name: "SmsConfiguration",
  mixins: [Globals],
  components: {
    // ChannelDatatable,
    Form,
    Field,
    // Editor
  },
  data() {
    return {
      show: false,
      templateId: "",
      eventTemplateId: 0,
      eventName: "",
      eventId: "",
      smsBody: "",
      toggleFlag: true,
      smsBodyError: false,
      templateLength: 0,
      standardVariable: [] as any,
      render: false,
      items: [],
      validation_errors: {
        templateId:'',
        text: ''
      }
    };
  },
  mounted() {
    const standard_variable = localStorage.getItem('standard_variable');
    if (standard_variable) {
      this.items = JSON.parse(standard_variable);
    }
  },
  methods: {
    reset_validation_errors(){
      this.validation_errors.text = ""
      this.validation_errors.templateId = ""
    },
    get_all_template_variables(template){
      const variable_list = this.standardVariable.map(item => item.variable)
      // console.log("this.standardVariable", this.standardVariable);
      const regex = /##(.*?)##/g;
      const matches = template.match(regex);
      let variable_str = ""
      if (matches) {
        matches.map(match => {
          console.log("variables-->>", match);
          match = match.replaceAll("##", "");
          if (!variable_list.includes(match)){
            variable_str = variable_str +" "+ match
          }
        })
        if (variable_str.length == 0){
          return false
        }
        return variable_str;
      } 
      return false;

    },
    validate_form(formData){
      this.reset_validation_errors();
      if (this.smsBody.length === 0 ) {
        this.validation_errors.text = "SMS text field is required.";
      }
      
      let is_error = this.get_all_template_variables(this.smsBody);
      if (is_error ){
        this.validation_errors.text = "SMS text has invalid template variable"+ is_error;
      }
    },
    validate() {
      if (this.smsBody.length == 0) {
        this.smsBodyError = true
      }
      else {
        this.smsBodyError = false
      }
    },
    saveSMSConfigureData(formData) {
      this.validate_form(formData);
      if(this.smsBody.length==0)
          {
            this.smsBodyError=true
          }
          else
          {
            this.smsBodyError=false
          }
      // const formData: any = {
      //   PCSConfigurationId: 2,
      //   eventId: eventID,
      //   isEnabled: event.target.checked,
      //   eventTemplate: defaultEmailTemplate
      // }
      formData.PCSConfigurationId = 1,
        formData.eventId = this.eventId,
        formData.isEnabled = true,
        formData.eventTemplate.text = this.smsBody
        formData.eventTemplate.templateId = this.templateId
      // formData.distributorId=this.globalData.organizationId
      // formData.eventName=this.eventName
      // formData.communicationChannel="sms"
      // formData.templateId=this.templateId
      // formData.templateBody.body=this.smsBody
      if(this.validation_errors.templateId.length === 0 && this.validation_errors.text.length === 0)
      {
      ApiService.patch(TEMPLATES + this.eventTemplateId, formData).then((data) => {
        hideModal(this.smsConfigurationModalRef);
        ElNotification({
              title: "Success",
              message: "Updated Successfully!",
              type: "success",
              position: "top-right",
            });
        this.$emit("callEvent", "");
      })
        .catch((error) => {
          console.log(error);
        });
      }
      // else if(this.templateLength==0 && this.smsBody.length!=0)
      // {
      //   ApiService.post(TEMPLATES,formData).then((data) => {
      //       hideModal(this.smsConfigurationModalRef);
      //       this.$emit("callEvent", "");
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
      // }

    },
    callSMSConfigureModal(id, name, template, variable, eventTemplateId) {

      this.eventId = id
      this.eventName = name
      this.standardVariable = variable
      this.items = JSON.parse(localStorage.getItem('standard_variable'))
      // if( Object.keys(template).length>0 && 'email' in template)
      // {
      this.smsBody = template.text;
      this.templateId = template.templateId ? template.templateId : 0;
      this.eventTemplateId = eventTemplateId;

      // this.emailSubject=template.subject
      // this.emailDisclaimer=template.email.text.disclaimer
      // if ('sms' in template){
      //   this.templateLength = 1
      // }
      // else{
      //   this.templateLength = 0
      // }
      // this.eventName=name
      // this.standardVariable=variable
      // this.items=JSON.parse(localStorage.getItem('standard_variable'))
      // if(Object.keys(template).length>0 && 'sms' in template)
      // {
      // this.smsBody=template.sms.text.body
      // this.templateId=template.sms.templateId
      // }
      // else
      // {
      //   this.smsBody=""
      //   this.templateId=""
      // }

      this.render = true
    }
  },

  setup() {
    const formValidation = Yup.object().shape({
      templateId: Yup.string().matches(TEMPLATEID, "Please enter valid template id").required().label("Template id")
    });
    const smsConfigurationModalRef = ref<null | HTMLElement>(null);
    const init = ref({
      selector: 'textarea#open-source-plugins',
      height: 200,
      menubar: false,
      plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code',
      imagetools_cors_hosts: ['picsum.photos'],
      // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | code | fullscreen  preview save print | insertfile image media codesample | ltr rtl',
      autosave_ask_before_unload: true,
      autosave_interval: '5s',
      image_advtab: true,
      image_caption: true,
      automatic_uploads: true,
      content_style: 'body { font-family:helvetica, arial, sans-serif; font-size:14px }'
    })
    //  const formData: any = {
    //   PCSConfigurationId: 2,
    //   eventId: eventID,
    //   isEnabled: event.target.checked,
    //   eventTemplate: defaultEmailTemplate
    // }
    const formData = ref({
      PCSConfigurationId: 1,
      eventId: 1,
      isEnabled: false,
      eventTemplate: {
        text: "",
        templateId: ''

      }
    })
    const tableHeader = ref([
      {
        name: "Variable",
        key: "variable",
        sortable: true,
      },
      {
        name: "Variable Description",
        key: "variableDescription",
      },
    ]);

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Email Configuration", ["Setting"]);
    });

    return {
      tableHeader,
      formData,
      init,
      smsConfigurationModalRef,
      formValidation
    };
  },
});
